import "./Preloader.css";

const Preloader = ({ isOpen }) => {
  return (
    <>
      {isOpen && (
        <div className="preloader">
          <div className="preloader__overlay"></div> {/* Добавленный overlay */}
          <div className="preloader__container">
          <div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <span className="preloader__round"></span>
            <p className="preloader__text">Updating ...</p>{" "}
            {/* Добавленная надпись */}
          </div>
        </div>
      )}
    </>
  );
};

export default Preloader;
